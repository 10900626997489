<template>
	<div class="social-buttons-wrapper">
		<div class="single-button-wrapper" v-for="(social, i) in socials" :key="i">
			<v-slide-y-reverse-transition>
				<v-btn :class="'background-' + pageColor" fab v-if="visibleButtons">
					<ShareNetwork
						class="social-button"
						:url="getUrl()"
						:title="title"
						:network="social"
						hashtags="aili"
					>
						<v-icon :color="'white'">mdi-{{ social }}</v-icon>
					</ShareNetwork>
				</v-btn>
			</v-slide-y-reverse-transition>
		</div>

		<v-btn
			outlined
			fab
			class="background-aili-white"
			@click="visibleButtons = !visibleButtons"
			:color="$vuetify.breakpoint.mobile ? color : 'white'"
		>
			<v-icon class="social-button" :color="color">mdi-share-variant</v-icon>
		</v-btn>
	</div>
</template>

<style lang="scss" scoped></style>

<script>
export default {
	name: 'SocialIcons',
	props: ['value', 'name', 'title', 'color'],
	data() {
		return {
			visibleButtons: false,
			socials: ['facebook', 'email', 'whatsapp', 'telegram'],
		};
	},
	methods: {
		getUrl() {
			return window.location.href;
		},
	},
};
</script>
<style lang="scss" scoped>
.social-buttons-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	float: right;
	height: 20vh;
	margin-top: -100%;
	position: sticky;
	top: 74vh;
	z-index: 12;
	margin-bottom: 10px;
	@media (min-width: $desktop) {
		transform: translate(100%, 0);
		margin-right: -20px;
	}
	@media (max-width: $tablet-l) {
		margin-right: 10px;
	}

	.single-button-wrapper {
		margin-bottom: 10px;
	}
}
.social-button-wrapper::after {
	opacity: 0;
}

a {
	color: transparent !important;
}
</style>
