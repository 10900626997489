<template>
	<div class="single-recipe">
		<Header />
		<div class="content">
			<MainColumn>
				<p class="top-title color-aili-white font-size-50">Ricette</p>
				<CategoryHeaderRecipes v-if="recipeCategories.length > 0" />
				<div class="content-shadow" v-if="post">
					<SocialIcons :color="'#f72585'" :title="post.title.rendered" />
					<RenderBlocks :parentBlock="post" :blocks="blocks" />
				</div>
				<div class="last-recipes background-aili-pink-light">
					<div class="content-shadow">
						<ChefResult v-if="author" :author="author" color="aili-pink" />
					</div>
				</div>
			</MainColumn>
		</div>
	</div>
</template>

<script>
import Header from '@/components/ui/Header.vue';
import ChefResult from '@/components/ui/ChefResult.vue';
import CategoryHeaderRecipes from '@/components/ui/CategoryHeaderRecipes.vue';
import SocialIcons from '@/components/ui/SocialIcons.vue';
export default {
	name: 'Recipe',
	components: {
		Header,
		CategoryHeaderRecipes,
		ChefResult,
		SocialIcons,
		RenderBlocks: () => import('@/components/blocks/RenderBlocks.vue')
	},
	metaInfo() {
		if (this.seo) {
			return this.formatYoastMeta(this.seo);
		}
	},
	data() {
		return {
			post: null,
			blocks: null,
			author: null
		};
	},

	async mounted() {
		await this.getRecipeCategories();

		this.updateProperty({
			property: 'pageColor',
			value: 'aili-pink'
		});

		let url = '/wp/v2/recipe';
		let response;
		if (this.$route.query && this.$route.query.preview) {
			response = await this.getPreview(url);
		} else {
			let params = {
				slug: this.$route.params.slug
			};
			response = await this.$api.get(url, {
				params: params
			});
		}
		this.post = response.data[0];

		this.setSchemaJson(this.post.qzr_schema);
		this.blocks = response.data[0].blocks;

		this.updateProperty({
			property: 'seo',
			value: this.post
		});

		// Prendo gli chef
		let response_author = await this.$api.get('/qzr-data/authors/chefs/' + this.post.author_data.slug);
		let author = response_author.data;

		if (author) {
			// Prendo 3 ricette per ogni chef
			let response_last_recipes = await this.$api.get('/wp/v2/recipe', {
				params: {
					per_page: 3,
					'author[]': author.id
				}
			});
			author.last_recipes = response_last_recipes.data;
		}
		this.author = author;
	}
};
</script>
<style lang="scss" scoped>
h1 {
	padding: 0;
	padding-bottom: $spacing-1;
	margin: 0;
}

#app .subtitle {
	@media (max-width: $tablet-m) {
		margin: 5px 0;
		line-height: 1.3;
	}
}
.single-recipe {
	min-height: 100vh;
	padding-bottom: $footer-distance;
	background: $aili-pink;
}

.last-recipes {
	margin-top: $spacing-1;
}
</style>
